.Join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
}

.left-j>hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin-top: -10px;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container {
    display: flex;
    gap: 3rem;
    background-color: grey;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.email-container>input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}

::placeholder {
    color: var(--lightgray);
}

.btn-j {
    background-color: orange;
    color: white;
    border-radius: 5px;
}

@media screen and (max-width : 768px) {

    .Join {
        flex-direction: column;
        gap: 1rem;
    }

    .left-j {
        font-size: x-large;
        flex-direction: column;
    }

    .email-container {
        gap: 0rem;
        border-radius: 5px;
    }

    .btn-j {
        border-radius: 5px;
        height: 2rem;
        font-weight: 200;
        font-size: x-small;
    }
}